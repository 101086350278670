import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';

const EmbargoColumn = (props) => {
  const { embargo } = props;

  return (
    <>
      <ul data-testid='ulEmbargoTypes'>
        {
          embargo.embargoTypes.map((e) => (
            <li key={e} data-testid='liEmbargoTypes'>
              <Typography align='left' fontSize='small'>
                {e}
              </Typography>
            </li>
          ))
        }
      </ul>
      {embargo.url && embargo.url !== '' &&
        <Typography align='left' data-testid='urlTypography' sx={{ ml: 5 }}>
          <Link
            data-testid='urlLink'
            fontSize='small'
            color='secondary'
            href={embargo.url}
            target='_blank'
            rel='noopener'
            underline='always'>
            more information
          </Link>
        </Typography>
      }
    </>
  );
}

EmbargoColumn.propTypes = {
  embargo: PropTypes.object.isRequired,
};

export default EmbargoColumn;