import { useAxios } from './useAxios';

const useGetTransactions = () => useAxios({
  method: 'get',
  endPoint: 'transactions'
});

const useGetTransactionById = () => useAxios({
  method: 'get',
  endPoint: `transaction/`
});

const useCreateTransaction = () => useAxios({
  method: 'post',
  endPoint: 'transaction'
});

export { useGetTransactions, useGetTransactionById, useCreateTransaction };