const boxSx = () => ({ mt: 3 });

const titleSx = () => ({ mb: 3, fontWeight: 'bold' });

const fabSx = () => ({
  position: 'absolute',
  top: 108,
  right: 20
});

const addIconSx = () => ({ mr: 1 });

export {
  boxSx,
  titleSx,
  fabSx,
  addIconSx
};