import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';

const DocumentLinks = (props) => {
  const { items } = props;

  return (
    <ul data-testid='ulDocuments'>
      {items.map((item) => (
        <li key={item.label} data-testid='liDocuments'>
          <Typography align='left' data-testid='urlTypography'>
            <Link
              data-testid='urlLink'
              fontSize='medium'
              color='secondary'
              href={item.url}
              target='_blank'
              rel='noopener'
              underline='always'>
              {item.label}
            </Link>
          </Typography>
        </li>
      ))}
    </ul>
  );
}

DocumentLinks.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired
  })).isRequired
};

export default DocumentLinks;