import { useState, useEffect, useContext } from 'react';
import { store } from '../store';
import api from './api';

const useAxios = (props) => {
  const {
    method,
    endPoint
  } = props;

  const [data, setData] = useState(undefined);
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [params, setParams] = useState(false);
  const { state: { accessToken } } = useContext(store);

  useEffect(() => {
    const sendRequest = async () => {
      const url = params === true? endPoint: `${endPoint}${params}`;
      if(accessToken) {
        try {
          setError(false);
          setIsLoading(true);
          const result = await api(accessToken)[method](url);
          setData(result.data);
        } catch (error) {
          setError(error);
        } finally {
          setIsLoading(false);
        }
      }
    }

    if(params) sendRequest();
  }, [accessToken, endPoint, method, params]);

  return [{ data, error, isLoading }, setParams];
}

export { useAxios };
