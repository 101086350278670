import { Routes, Route, Navigate } from 'react-router-dom';
import AuthorizedRoute from '../components/AuthorizedRoute';
import TransactionChecks from '../pages/TransactionChecks';
import CountryRisk from '../pages/CountryRisk';
import SanctionSearches from '../pages/SanctionSearches';
import Documents from '../pages/Documents';
import SideMenu from './SideMenu';
import TransactionCheck from '../pages/TransactionCheck';
import SanctionSearch from '../pages/SanctionSearch';
import Instructions from '../pages/Instructions';

const Routing = () => {
  return (
    <Routes>
      <AuthorizedRoute path='/' element={<SideMenu />} >
        <AuthorizedRoute index element={<Navigate replace to='/transactionchecks'/>} /> 
        <AuthorizedRoute path='transactionchecks' element={<TransactionChecks title='Transaction Checks' />} />
        <AuthorizedRoute path='countryrisk' element={<CountryRisk title='Country Risk' />} />
        <AuthorizedRoute path='sanctionsearches' element={<SanctionSearches title='Sanction Searches' />} />
        <AuthorizedRoute path='documents' element={<Documents title='Documents' />} />
        <AuthorizedRoute path='instructions' element={<Instructions title='Instructions' />} />
        <AuthorizedRoute path='transactioncheck/:id' element={<TransactionCheck />} />
        <AuthorizedRoute path='quickcheck/:id' element={<TransactionCheck />} />
        <AuthorizedRoute path='sanctionsearch/:id' element={<SanctionSearch />} />
      </AuthorizedRoute>
      <Route path='*' element={<h1>Not Found</h1>} />
    </Routes>
  );
};

export default Routing;