import axios from 'axios';

const baseURL = process.env.REACT_APP_AWS_DOMAIN;

const api = (token) => axios.create({
  baseURL,
  responseType: 'json',
  headers: {
    Authorization: `Bearer ${token}`,
    accept: '*/*'
  }
});

export default api;