import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { createBrowserHistory } from 'history';
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import theme from './theme';
import Routing from './components/Routing';
import Loading from './components/Loading';

export const history = createBrowserHistory();

const App = () => {
  const { isLoading } = useAuth0();
  if (isLoading) {
    return <Loading open={isLoading} />
  }

  return (
    <Router history={history}>
      <ThemeProvider theme={theme}>
        <CssBaseline >
          <Routing />
        </CssBaseline>
      </ThemeProvider>
    </Router>
  );
}

export default App;
