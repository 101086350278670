import { textFilterInput } from '../helper/coboTableFilterComponents';
import EmbargoColumn from './EmbargoColumn';

const isEmbargoTypeMatched = (value, filterValue) => {
  for (let i = 0; i < value.embargoTypes.length; i++) {
    const type = value.embargoTypes[i];
    if (type.toLowerCase().includes(filterValue.toLowerCase())) return true;
  }
  return false;
};

const countryRiskTableColumns = [
  {
    id: 'country',
    label: 'Country',
    sortingEnabled: true,
    style: (value) => ({ padding: 0 }),
    filterInput:  textFilterInput
  },
  {
    id: 'ch',
    label: 'Swiss Embargo',
    style: (value) => ({ padding: 0 }),
    formattedValue: (embargo) => (
      embargo && <EmbargoColumn embargo={embargo} />
    ),
    filterInput: textFilterInput,
    isFilterMatched: isEmbargoTypeMatched
  },
  {
    id: 'eu',
    label: 'EU Embargo',
    style: (value) => ({ padding: 0 }),
    formattedValue: (embargo) => (
      embargo && <EmbargoColumn embargo={embargo} />
    ),
    filterInput: textFilterInput,
    isFilterMatched: isEmbargoTypeMatched
  },
  {
    id: 'us',
    label: 'US Embargo',
    style: (value) => ({ padding: 0 }),
    formattedValue: (embargo) => (
      embargo && <EmbargoColumn embargo={embargo} />
    ),
    // filterInput: textFilterInput,
    // isFilterMatched: isEmbargoTypeMatched
  }
];

export default countryRiskTableColumns;