import React, { useState, useEffect } from 'react';
import { Outlet, useNavigate, useLocation } from 'react-router-dom';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import AppBarContainer from './AppBarContainer';
import DrawerBox from './DrawerBox';
import { MENU_ITEMS } from './SideDrawer';
import {
  boxContainerSx,
  boxPageSx
} from '../styles/SideMenu.style';
import Login from './Login';

const SideMenu = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [mobileOpen, setMobileOpen] = useState(false);
  const [selectedItemIndex, setSelectedItemIndex] = useState(0);

  const setSelectedMenuItem = (pathName) => {
    const path = pathName.split('/')[1];
    for (let i = 0; i < MENU_ITEMS.length; i++) {
      if (MENU_ITEMS[i].path === path) setSelectedItemIndex(i)
    }
  }

  useEffect(() => {
    setSelectedMenuItem(location.pathname)
  }, [location]);

  const handleMenuItemClick = (index) => {
    navigate(MENU_ITEMS[index].path);
    setMobileOpen(false);
  }

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  return (
    <Box sx={boxContainerSx()}>
      <AppBarContainer handleDrawerToggle={handleDrawerToggle}/>
      <DrawerBox 
        mobileOpen={mobileOpen}
        selectedItemIndex={selectedItemIndex}
        handleDrawerToggle={handleDrawerToggle}
        handleMenuItemClick={handleMenuItemClick} />
      <Box
        component="main"
        sx={boxPageSx()}
      >
        <Toolbar />
        <Login />
        <Outlet />
      </Box>
    </Box>
  );
}

export default SideMenu;
