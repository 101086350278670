import createTheme from '@mui/material/styles/createTheme';
import grey from '@mui/material/colors/grey';

const primaryColor = '#021f47';
const secondary = '#008ED7';
const tertiary = grey[400];
const bryterButtonColor = '#011260';
const textPrimaryColor = '#7a7a7a';

const theme = createTheme({
  palette: {
    primary: {
      main: primaryColor,
    },
    secondary: {
      main: secondary,
    },
    tertiary: {
      main: tertiary
    },
    bryterButton: {
      main: bryterButtonColor,
      contrastText: '#fff'
    },
    text: {
      main: textPrimaryColor
    }
  },
  components: {
    MuiDrawer: {
      styleOverrides: {
        paper: {
          backgroundColor: primaryColor,
          color: secondary
        }
      }
    }
  }
});

export default theme;