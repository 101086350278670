import PropTypes from 'prop-types';
import DashboardIcon from '@mui/icons-material/Dashboard';
import WarningIcon from '@mui/icons-material/Warning';
import ListIcon from '@mui/icons-material/List';
import DescriptionIcon from '@mui/icons-material/Description';
import List from '@mui/material/List';
import ListItemText from '@mui/material/ListItemText';
import Toolbar from '@mui/material/Toolbar';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import HelpIcon from '@mui/icons-material/Help';
import { listSx, listItemButtonSx, listItemIconSx } from '../styles/SideMenu.style';

export const MENU_ITEMS = [
  {
    key: 'transactionChecksItem',
    text: 'Transaction Checks',
    path: 'transactionchecks',
    icon: <DashboardIcon />
  },
  {
    key: 'sanctionSearchesItem',
    text: 'Sanction Searches',
    path: 'sanctionsearches',
    icon: <ListIcon />
  },
  {
    key: 'countryRiskItem',
    text: 'Country Risk',
    path: 'countryrisk',
    icon: <WarningIcon />
  },
  {
    key: 'documentsItem',
    text: 'Documents',
    path: 'documents',
    icon: <DescriptionIcon />
  },
  {
    key: 'instructionsItem',
    text: 'Instructions',
    path: 'instructions',
    icon: <HelpIcon />
  }
];

const SideDrawer = (props) => {
  const { onItemClick, selectedItemIndex } = props;

  return (
    <>
      <Toolbar />
      <List sx={listSx()}>
        {MENU_ITEMS.map((item, index) => (
          <ListItemButton
            key={item.key}
            sx={listItemButtonSx(selectedItemIndex === index)}
            onClick={(event) => onItemClick(event, index)}>
            <ListItemIcon sx={listItemIconSx()}>
              {item.icon}
            </ListItemIcon>
            <ListItemText primary={item.text} />
          </ListItemButton>
        ))}
      </List>
    </>
  );
}

SideDrawer.propTypes = {
  onItemClick: PropTypes.func.isRequired,
  selectedItemIndex: PropTypes.number.isRequired
};

export default SideDrawer;