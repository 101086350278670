import React, { createContext, useReducer } from 'react';

const initialState = {
	accessToken: undefined
};
const store = createContext(initialState);
const { Provider } = store;

const StateProvider = ({ children }) => {
	const [state, dispatch] = useReducer((state, action) => {
		switch (action.type) {
			case 'ID_TOKEN':
				return {
					...state,
					accessToken: action.payload
				}
			default:
				break;
		}
	}, initialState);

	return <Provider value={{ state, dispatch }}>{children}</Provider>
};

export { store, StateProvider };
