import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import AppBar from '@mui/material/AppBar';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Profile from '../components/Profile';
import {
  menuIconButtonSx,
  appBarSx,
  boxProfileSx
} from '../styles/SideMenu.style';

const AppBarContainer = (props) => {
  const {
    handleDrawerToggle,
  } = props
  
  return (
    <AppBar
      position='fixed'
      sx={appBarSx()}
    >
      <Toolbar>
        <IconButton
          color='inherit'
          aria-label='open drawer'
          edge='start'
          onClick={handleDrawerToggle}
          sx={menuIconButtonSx()}
        >
          <MenuIcon />
        </IconButton>
        <Box sx={boxProfileSx()}>
          <Profile />
        </Box>
      </Toolbar>
    </AppBar>
  );
}

AppBarContainer.propTypes = {
  handleDrawerToggle: PropTypes.func.isRequired
};

export default AppBarContainer;