import { useAxios } from './useAxios';

const useGetSanctionSearches = () => useAxios({
  method: 'get',
  endPoint: 'sanctionsearches'
});

const useGetSanctionSearchById = () => useAxios({
  method: 'get',
  endPoint: `sanctionsearch/`
});

const useCreateSanctionSearch = () => useAxios({
  method: 'post',
  endPoint: 'sanctionsearch'
});

export { useGetSanctionSearches, useGetSanctionSearchById, useCreateSanctionSearch };