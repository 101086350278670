import React, { useEffect, useState, useContext } from 'react';
import { store } from '../store';
import { useNavigate, useParams } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { useGetSanctionSearchById } from '../utils/sanctionSearchService';
import BryterForm, { BRYTER_FORM_MAPPER } from '../components/BryterForm';

const SanctionSearch = () => {
  const { state: { accessToken } } = useContext(store);
  const { user } = useAuth0();
  const [getSanctionSearchResponse, getSanctionSearch] = useGetSanctionSearchById();
  const { id } = useParams();
  const [sanctionSearch, setSanctionSearch] = useState();
  const navigate = useNavigate();

  useEffect(() => {
    const getSanctionSearchById = async () => {
			getSanctionSearch(id);
      if(!getSanctionSearchResponse.isLoading) {
        setSanctionSearch(getSanctionSearchResponse.data);
      }
		};
		if (accessToken) getSanctionSearchById()
  }, [accessToken, getSanctionSearch, getSanctionSearchResponse, id]);

  const bryterFormMap = (status) => (
    status === 'Completed'?
      BRYTER_FORM_MAPPER.sanctionSearchResult:
      BRYTER_FORM_MAPPER.sanctionSearch
  );

  return sanctionSearch && user? (
    <BryterForm
      bryterFormMap={bryterFormMap(sanctionSearch.status)}
      processId={id}
      userId={user.email}
      sessionId={sanctionSearch.status === 'Completed'? null: sanctionSearch.sessionId}
      navigateBack={(p) => navigate(p)}/>
  ): null;
}

export default SanctionSearch;