import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { useGetTransactionById } from '../utils/transactionService';
import BryterForm, { BRYTER_FORM_MAPPER } from '../components/BryterForm';

const getBryterFormMap = (path, status) => {
  for (const [, value] of Object.entries(BRYTER_FORM_MAPPER)) {
    if (value.path === path && value.status === status) return value;
  }
};

const getFormPathAndId = (location) => {
  const pathNameArray = location.pathname.split('/');
  pathNameArray.shift();
  return pathNameArray;
} 

const TransactionCheck = () => {
  const { user } = useAuth0();
  const navigate = useNavigate();
  const location = useLocation();
  const [transaction, setTransaction] = useState();
  const [bryterFormMap, setBryterFormMap] = useState();

  const [path, id] = getFormPathAndId(location);
  const [getTransactionByIdResponse, getTransactionById] = useGetTransactionById(id);

  useEffect(() => {
    const getTransaction = async () => {
			getTransactionById(id);
      if(!getTransactionByIdResponse.isLoading) {
        const transactionData = getTransactionByIdResponse.data;
        setTransaction(transactionData);
        setBryterFormMap(getBryterFormMap(path, transactionData.status));
      }
		};
		getTransaction()
  }, [getTransactionByIdResponse, getTransactionById, id, path]);

  return transaction && bryterFormMap && user? (
    <BryterForm
      bryterFormMap={bryterFormMap}
      processId={id}
      userId={user.email}
      sessionId={transaction.status === 'Completed'? null: transaction.sessionId}
      navigateBack={(p) => navigate(p)}/>
  ): null;
}

export default TransactionCheck;