import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Chip from '@mui/material/Chip';
import DocumentLinks from '../components/DocumentLinks';
import documents from '../components/documentList';
import PageTitle from '../components/PageTitle';
import * as styles from '../styles/TransactionChecks.style';

const Documents = ({ title }) => (
  <Box sx={styles.boxSx()}>
    <PageTitle title={title} />
    {documents.map((doc) => (
      <Box key={doc.name}>
        <Divider>
          <Chip label={doc.name} />
        </Divider>
        <DocumentLinks items={doc.items} />
      </Box>
    ))}
  </Box>
);

Documents.propTypes = {
  title: PropTypes.string.isRequired
};

export default Documents;