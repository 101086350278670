import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router';
import { useGetTransactions, useCreateTransaction } from '../utils/transactionService';
import AddIcon from '@mui/icons-material/Add';
import Box from '@mui/material/Box';
import Fab from '@mui/material/Fab';
import CircularProgress from '@mui/material/CircularProgress';
import CoboTable from '../components/CoboTable';
import PageTitle from '../components/PageTitle';
import SelectTransactionDialog from '../components/SelectTransactionDialog';
import transactionTableColumns from '../components/transactionTableColumns'
import * as styles from '../styles/TransactionChecks.style';

const TransactionChecks = ({ title }) => {
	const [getTransactionsResponse, getTransactions] = useGetTransactions();
	const [createTransactionResponse, createTransaction] = useCreateTransaction();
	const [transactions, setTransactions] = useState();
	const [openTransaction, setOpenTransaction] = useState(false);
	const [selectedTransactionType, setSelectedTransactionType] = useState(false);

	const navigate = useNavigate();

	useEffect(() => {
		getTransactions(true)
		if(!getTransactionsResponse.isLoading) {
			setTransactions(getTransactionsResponse.data.Items);
		}
	}, [getTransactionsResponse, getTransactions]);

	useEffect(() => {
		if(!createTransactionResponse.isLoading) {
			navigate(`/${selectedTransactionType.path}/${createTransactionResponse.data.id}`);
		}
	}, [createTransactionResponse, selectedTransactionType, navigate]);

	const handleSelectTransaction = async (value) => {
		setOpenTransaction(false);
		createTransaction(true);
		setSelectedTransactionType(value)
	};

	const handleTableRowClick = (row) => {
		if (row.transactionType && row.transactionType === 1) {
			navigate(`/quickcheck/${row.id}/`);
		} else {
			navigate(`/transactioncheck/${row.id}/`);
		}
	};

	return (
		<Box sx={styles.boxSx()}>
			<PageTitle title={title} />
			<Fab
				variant="extended"
				color="primary"
				label='New Transaction'
				sx={styles.fabSx()}
				onClick={() => setOpenTransaction(true)}>
				<AddIcon sx={styles.addIconSx()} />New
			</Fab>
			{transactions ?
				<CoboTable
					data={transactions}
					columns={transactionTableColumns}
					onRowClick={(row) => handleTableRowClick(row)} /> :
				<Box sx={{ display: 'flex' }}>
					<CircularProgress />
				</Box>}
			<SelectTransactionDialog
				open={openTransaction}
				onSelect={(value) => handleSelectTransaction(value)}
				onClose={() => setOpenTransaction(false)} />
		</Box>
	);
};

TransactionChecks.propTypes = {
  title: PropTypes.string.isRequired
};

export default TransactionChecks;