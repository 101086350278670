import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import ReactTooltip from 'react-tooltip';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import MapChart from '../components/MapChart';
import CoboTable from '../components/CoboTable';
import countryRiskTableColumns from '../components/countryRiskTableColumns';
import PageTitle from '../components/PageTitle';
import * as styles from '../styles/TransactionChecks.style';
import worldMapJson from '../worldMap.geo.json';
import countryRiskTableData from '../countryRiskTableData.json';

const convertEmbargos = (embargo) => ({
  ch: embargo.CH,
  eu: embargo.EU,
  us: embargo.US
});

const worldMapJsonMapping = (worldMapGeo) => {
  return worldMapGeo
    .filter(element => element.EMBARGO)
    .map(element => ({ id: element.NAME, country: element.NAME, ...convertEmbargos(element.EMBARGO) }));
};

const CountryRisk = ({ title }) => {
  const [content, setContent] = useState('');
  const coboTableRef = useRef(null);

  useEffect(() => {
    return () => {
      window.scrollTo(0, 0);
    };
  }, []);

  const scrollToCoboTable = () => coboTableRef.current.scrollIntoView({ behavior: 'smooth' });
  const scrollToMap = () => window.scrollTo({
    top: 0,
    left: 0,
    behavior: 'smooth'
  });

  return (
    <Box sx={styles.boxSx()}>
      <PageTitle title={title} />
      <MapChart
        scrollToCoboTable={() => scrollToCoboTable()}
        setTooltipContent={setContent}
        worldMapJson={worldMapJson} />
      <ReactTooltip>{content}</ReactTooltip>
      <Divider />
      <Box sx={{ mt: 2 }} ref={coboTableRef}>
        <Box  sx={{ display: 'flex', flexDirection: 'row' }}>
          <Typography variant='h6' color='primary' sx={{ fontWeight: 'bold' }}>
            Country Risk Table
          </Typography>
          <Button variant='text' sx={{ ml: 2, '&:hover': { backgroundColor: '#fff'}}} onClick={() => scrollToMap()}>Go to Map</Button>
        </Box>
        <CoboTable
          data={worldMapJsonMapping(countryRiskTableData.countries)}
          columns={countryRiskTableColumns}
          defaultColumnOrderId='country'
          orderDirection='asc'
          defaultRowsPerPage={100} />
      </Box>
    </Box>
  );
}

CountryRisk.propTypes = {
  title: PropTypes.string.isRequired
};

export default CountryRisk;