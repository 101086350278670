import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import ListItemText from '@mui/material/ListItemText';
import Link from '@mui/material/Link';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';

const EmbargoList = (props) => {
  const { embargo, embargoListName } = props;

  if (!embargo) return null;

  return (
    <Box sx={{ mb: 3 }}>
      <Typography align='left' variant='subtitle1' sx={{ ml: 3, fontWeight: 'bold' }}>
        {embargoListName}
      </Typography>
      <Divider />
      <ul>
        {
          embargo.embargoTypes.map((e) => (
            <li key={e}>
              <ListItemText sx={{ mr: 2 }} primary={e} />
            </li>
          ))
        }
      </ul>
      {embargo.url && embargo.url !== '' &&
        <Typography align='right'>
          <Link
            align='right'
            sx={{ mr: 3 }}
            fontSize='small'
            color='secondary'
            href={embargo.url}
            target='_blank'
            rel='noopener'
            underline='always'>
            more information
          </Link>
        </Typography>
      }
    </Box>
  );
}

EmbargoList.propTypes = {
  embargo: PropTypes.shape({
    NAME: PropTypes.string.isRequired,
    EMBARGO: PropTypes.object,
  }).isRequired,
  embargoListName: PropTypes.string.isRequired
};

export default EmbargoList;