const documents = [
  {
    name: 'Switzerland',
    items: [
      {
        label: 'SR 946.202 - Bundesgesetz vom 13. Dezember 1996 über die Kontrolle zivil und militärisch verwendbarer Güter sowie besonderer militärischer Güter (Güterkontrollgesetz, GKG)',
        url: 'https://www.admin.ch/opc/de/classified-compilation/19960740/index.html'
      },
      {
        label: 'SR 946.202.1 - Verordnung vom 3. Juni 2016 über die Kontrolle zivil und militärisch verwendbarer Güter, besonderer militärischer Güter sowie strategischer Güter (Güterkontrollverordnung, GKV)',
        url: 'https://www.admin.ch/opc/de/classified-compilation/20151950/index.html'
      },
      {
        label: 'Anhänge 1 und 2 GKV (Dual Use 2021); gültig ab 1.05.2021 (PDF, 3 MB, 01.10.2021)',
        url: 'https://www.seco.admin.ch/dam/seco/de/dokumente/Aussenwirtschaft/Wirtschaftsbeziehungen/Exportkontrollen/Industrieprodukte/G%C3%BCterlisten/anhang-1-2-gkv.pdf.download.pdf/Anhang_1_2_GKV_de.pdf'
      },
      {
        label: 'Anhang 3 GKV (2021); gültig ab 01.05.2021 (PDF, 1 MB, 10.05.2021)',
        url: 'https://www.seco.admin.ch/dam/seco/de/dokumente/Aussenwirtschaft/Wirtschaftsbeziehungen/Exportkontrollen/Industrieprodukte/G%C3%BCterlisten/Anhang%203%20GKV%20deutsch%20(Korr.%201).pdf.download.pdf/Anhang%203%20GKV%20deutsch%20(Korr.%201).pdf'
      },
      {
        label: 'Anhang 4 (Strategische Güter)',
        url: 'https://www.fedlex.admin.ch/eli/cc/2016/352/de#annex_4'
      },
      {
        label: 'Anhang 5 (Waffen/Sprengstoffe)',
        url: 'https://www.fedlex.admin.ch/eli/cc/2016/352/de#annex_5'
      },
      {
        label: 'Anhang 6 (Staaten des Schengen-Assoziierungsabkommen)',
        url: 'https://www.fedlex.admin.ch/eli/cc/2016/352/de#annex_6'
      },
      {
        label: 'Anhang 7 (Liste der Staaten nach Artikel 12 Absatz 1)',
        url: 'https://www.fedlex.admin.ch/eli/cc/2016/352/de#annex_7'
      },
      {
        label: 'End-user certificate (EUC) for dual-use goods (DOC, 29 kB, 16.01.2020)Endverbleibserklärung für Dual-Use-Güter',
        url: 'https://www.seco.admin.ch/dam/seco/de/dokumente/Aussenwirtschaft/Wirtschaftsbeziehungen/Exportkontrollen/Industrieprodukte/Formulare%20und%20Merkbl%C3%A4tter/euc.docx.download.docx/end%20user%20certificate%20for%20dual%20use%20goods.docx'
      },
      {
        label: 'End-user certificate (EUC) for goods for surveillance purposes (DOC, 56 kB, 11.11.2016)',
        url: 'https://www.seco.admin.ch/dam/seco/de/dokumente/Aussenwirtschaft/Wirtschaftsbeziehungen/Exportkontrollen/Industrieprodukte/Formulare%20und%20Merkbl%C3%A4tter/End-user%20certificate%20(EUC)%20for%20goods%20for%20surveillance%20purposes.docx.download.docx/End-user%20certificate%20(EUC)%20for%20goods%20for%20surveillance%20purposes.docx'
      },
      {
        label: 'End-user certificate (EUC) for Chemicals under the Chemical Weapons Convention (CWC) (DOC, 29 kB, 11.07.2018)Endverbleibserklärung für CWC-Chemikalien',
        url: 'https://www.seco.admin.ch/dam/seco/de/dokumente/Aussenwirtschaft/Wirtschaftsbeziehungen/Exportkontrollen/Industrieprodukte/Formulare%20und%20Merkbl%C3%A4tter/End-user%20certificate%20(EUC)%20for%20Chemicals%20under%20the%20Chemical%20Weapons%20Convention%20(CWC).docx.download.docx/End-user%20certificate%20(EUC)%20for%20Chemicals%20under%20the%20Chemical%20Weapons%20Convention%20(CWC)%20(corr.).docx'
      },
      {
        label: 'End-user certificate (EUC) for goods subject to national export controls (DOC, 54 kB, 09.12.2019)Endverbleibserklärung für national kontrollierte Güter',
        url: 'https://www.seco.admin.ch/dam/seco/de/dokumente/Aussenwirtschaft/Wirtschaftsbeziehungen/Exportkontrollen/Industrieprodukte/Formulare%20und%20Merkbl%C3%A4tter/end-user-certificateeuc_for_goods_subject_to_national_export_controls.docx.download.docx/End-user%20certificate%20(EUC)%20for%20goods%20subject%20to%20national%20export%20controls.docx'
      },
      {
        label: 'End-user certificate (EUC) for specific military goods (DOC, 55 kB, 09.12.2019)Endverbleibserklärung für besondere militärische Güter',
        url: 'https://www.seco.admin.ch/dam/seco/de/dokumente/Aussenwirtschaft/Wirtschaftsbeziehungen/Exportkontrollen/Industrieprodukte/Formulare%20und%20Merkbl%C3%A4tter/end-user_certificateeuc_for_specific_military_goods.docx.download.docx/End-user%20certificate%20(EUC)%20for%20specific%20military%20goods.docx'
      },
      {
        label: 'End-user certificate (EUC) for non-licensable industrial goods (DOC, 58 kB, 09.12.2019)Endverbleibserklärung für nicht kontrollierte Güter (Nullerbescheid)',
        url: 'https://www.seco.admin.ch/dam/seco/de/dokumente/Aussenwirtschaft/Wirtschaftsbeziehungen/Exportkontrollen/Industrieprodukte/Formulare%20und%20Merkbl%C3%A4tter/end-user-certificateeuc_for_non-licensable_industrial_goods.docx.download.docx/End-user%20certificate%20(EUC)%20for%20non-licensable%20industrial%20goods.docx'
      },
      {
        label: 'Fragebogen für Werkzeugmaschinen 2021 (DOC, 87 kB, 11.03.2021)',
        url: 'https://www.seco.admin.ch/dam/seco/de/dokumente/Aussenwirtschaft/Wirtschaftsbeziehungen/Exportkontrollen/Industrieprodukte/Formulare%20und%20Merkbl%C3%A4tter/formularwzm2021.docx.download.docx/Formular%20WZM%202021%20d.docx'
      },
      {
        label: 'Merkblatt zu Ausfuhren von Dual-Use-Werkzeugmaschinen in die Russische Föderation (PDF, 226 kB, 24.02.2021)',
        url: 'https://www.seco.admin.ch/dam/seco/de/dokumente/Aussenwirtschaft/Wirtschaftsbeziehungen/Exportkontrollen/Industrieprodukte/Formulare%20und%20Merkbl%C3%A4tter/merkblattzuausfuhrenvondual-use-werkzeugmaschinenindierussischefoederation.pdf.download.pdf/Merkblatt%20zu%20Ausfuhren%20von%20Dual-Use-Werkzeugmaschinen%20in%20die%20Russische%20F%C3%B6deration.pdf'
      },
      {
        label: 'Sie finden den Fragebogen ICP ("Nachweis zum ICP") sowie das Merkblatt zu ICP unter diesem Link.',
        url: 'https://www.seco.admin.ch/seco/de/home/Aussenwirtschaftspolitik_Wirtschaftliche_Zusammenarbeit/Wirtschaftsbeziehungen/exportkontrollen-und-sanktionen/elic/icp.html'
      },
      {
        label: 'ICP - Best Practices - e (PDF, 25 kB, 24.02.2016)',
        url: 'https://www.seco.admin.ch/dam/seco/de/dokumente/Aussenwirtschaft/Wirtschaftsbeziehungen/Exportkontrollen/Industrieprodukte/Formulare%20und%20Merkbl%C3%A4tter/ICP%20Best%20Practices.pdf.download.pdf/ICP%20-%20Best%20Practices%20-%20e.pdf'
      },
      {
        label: 'ICP Wassenaar Internal Compliance Programmes - e (PDF, 41 kB, 24.02.2016)',
        url: 'https://www.seco.admin.ch/dam/seco/de/dokumente/Aussenwirtschaft/Wirtschaftsbeziehungen/Exportkontrollen/Industrieprodukte/Formulare%20und%20Merkbl%C3%A4tter/ICP%20Wassenaar%20Internal%20Compliance%20Programmes.pdf.download.pdf/ICP%20Wassenaar%20Internal%20Compliance%20Programmes%20-%20e.pdf'
      },
      {
        label: 'Umsetzung der Exportkontrolle aus Sicht der Industrie (PDF, 787 kB, 12.02.2021)',
        url: 'https://www.seco.admin.ch/dam/seco/de/dokumente/Aussenwirtschaft/Wirtschaftsbeziehungen/Exportkontrollen/Industrieprodukte/Formulare%20und%20Merkbl%C3%A4tter/Leitfaden-Exportkontrolle_in_a_nutshell-DE.pdf.download.pdf/Leitfaden-Exportkontrolle_in_a_nutshell-DE.pdf'
      },
      {
        label: 'Strategic trade control outreach and industry compliance (PDF, 3 MB, 18.06.2019)',
        url: 'https://www.seco.admin.ch/dam/seco/de/dokumente/Aussenwirtschaft/Wirtschaftsbeziehungen/Exportkontrollen/Industrieprodukte/Formulare%20und%20Merkbl%C3%A4tter/Strategic%20Trade%20Control%20Outreach%20and%20Industry%20Compliance.pdf.download.pdf/Strategic%20Trade%20Control%20Outreach%20and%20Industry%20Compliance.pdf'
      },
      {
        label: 'Syrien-spezifische Liste (Juni 2018) (PDF, 49 kB, 28.06.2018)',
        url: 'https://www.seco.admin.ch/dam/seco/de/dokumente/Aussenwirtschaft/Wirtschaftsbeziehungen/Exportkontrollen/Industrieprodukte/G%C3%BCterlisten/Syrien-spezifische%20Liste%20Juni%202018%20.pdf.download.pdf/Syrien-spezifische%20Liste%20Juni%202018%20d.pdf'
      },
      {
        label: 'Einstufung von Bestandteilen für Rüstungsgüter (PDF, 113 kB, 19.07.2018)',
        url: 'https://www.seco.admin.ch/dam/seco/de/dokumente/Aussenwirtschaft/Wirtschaftsbeziehungen/Exportkontrollen/Industrieprodukte/Formulare%20und%20Merkbl%C3%A4tter/Klassifizierung.pdf.download.pdf/Klassifizierung%20d.pdf'
      },
      {
        label: 'Exportkontrollen - Behandlung des immateriellen Technologietransfers (PDF, 342 kB, 27.01.2021)',
        url: 'https://www.seco.admin.ch/dam/seco/de/dokumente/Aussenwirtschaft/Wirtschaftsbeziehungen/Exportkontrollen/Industrieprodukte/Formulare%20und%20Merkbl%C3%A4tter/ittgkgjanuar2021d.pdf.download.pdf/ITT%20GKG%20Januar%202021%20d.pdf'
      },
      {
        label: 'SR 946.202.21 - Verordnung vom 21. August 2013 über die Kontrolle von Chemikalien mit ziviler und militärischer Verwendungsmöglichkeit (Chemikalienkontrollverordnung, ChKV)',
        url: 'https://www.admin.ch/opc/de/classified-compilation/20121582/index.html'
      },
      {
        label: 'SR 946.202.3 - Verordnung vom 25. November 2020 über die Ausfuhr und Vermittlung von Gütern zur Internet- und Mobilfunküberwachung',
        url: 'https://www.admin.ch/opc/de/classified-compilation/20202374/index.html'
      },
      {
        label: 'SR 732.12 - Safeguardsverordnung vom 4. Juni 2021',
        url: 'https://www.fedlex.admin.ch/eli/cc/2021/359/de'
      },
      {
        label: 'SR 514.51 - Bundesgesetz vom 13. Dezember 1996 über das Kriegsmaterial (Kriegsmaterialgesetz, KMG)',
        url: 'https://www.admin.ch/opc/de/classified-compilation/19960753/index.html'
      },
      {
        label: 'SR 514.511 - Verordnung vom 25. Februar 1998 über das Kriegsmaterial (Kriegsmaterialverordnung, KMV)',
        url: 'https://www.admin.ch/opc/de/classified-compilation/19980112/index.html'
      },
      {
        label: 'SR 514.54 - Bundesgesetz vom 20. Juni 1997 über Waffen, Waffenzubehör und Munition (Waffengesetz, WG)',
        url: 'https://www.admin.ch/opc/de/classified-compilation/19983208/index.html'
      },
      {
        label: 'SR 514.541 - Verordnung vom 2. Juli 2008 über Waffen, Waffenzubehör und Munition (Waffenverordnung, WV)',
        url: 'https://www.admin.ch/opc/de/classified-compilation/20081148/index.html'
      },
      {
        label: 'SR 941.41 - Bundesgesetz vom 25. März 1977 über explosionsgefährliche Stoffe (Sprengstoffgesetz, SprstG)',
        url: 'https://www.admin.ch/opc/de/classified-compilation/19770064/index.html'
      },
      {
        label: 'SR 941.411 - Verordnung vom 27. November 2000 über explosionsgefährliche Stoffe (Sprengstoffverordnung, SprstV)',
        url: 'https://www.admin.ch/opc/de/classified-compilation/20002454/index.html'
      },
      {
        label: 'SR 946.231 Bundesgesetz vom 22. März 2002 über die Durchsetzung von internationalen Sanktionen (Embargogesetz, EmbG)',
        url: 'https://www.admin.ch/opc/de/classified-compilation/20000358/index.html'
      },
      {
        label: 'SR 935.41 Bundesgesetz vom 27. September über die im Ausland erbrachten privaten Sicherheitsdienstleistungen',
        url: 'https://www.fedlex.admin.ch/eli/cc/2015/450/de'
      },
      {
        label: 'SR 935.411 Verordnung vom 24. Juni 2015 über die im Ausland erbrachten privaten Sicherheitsdienstleistungen',
        url: 'https://www.fedlex.admin.ch/eli/cc/2015/451/de'
      },
      {
        label: 'BPS Wegleitung, Stand Januar 2021 (PDF, Anzahl Seiten 45, 1.6 MB, Deutsch)',
        url: 'https://www.eda.admin.ch/content/dam/eda/de/documents/aussenpolitik/sicherheitspolitik/wegleitung-BPS-ausland_DE.pdf'
      },
      {
        label: 'BPS Kurzanleitung betreffend Art. 8a VPS (PDF, 742.4 kB, Deutsch)',
        url: 'https://www.eda.admin.ch/content/dam/eda/de/documents/aussenpolitik/sicherheitspolitik/kurzanleitung-art8a-VPS_DE.pdf'
      }
    ]
  },
  {
    name: 'EU',
    items: [
      {
        label: 'Dual Use Verordnung 2021/821',
        url: 'https://eur-lex.europa.eu/legal-content/DE/TXT/PDF/?uri=OJ:L:2021:206:FULL&from=DE'
      },
      {
        label: 'Index Suche',
        url: 'https://www.eifec.org/down/2021_428_EIFEC_INDEX_DU_ITEMS_EN.pdf'
      },
      {
        label: 'Korrelationsliste HS-ECN',
        url: 'https://trade.ec.europa.eu/doclib/docs/2022/january/tradoc_160003.xlsx'
      },
      {
        label: 'Commission Recommendation on internal compliance programmes for dual-use trade controls under Council Regulation (EC) 428/2009, EU Commission, July 2019',
        url: 'https://eur-lex.europa.eu/legal-content/EN/TXT/PDF/?uri=CELEX:32019H1318&from=EN'
      },
      {
        label: 'Commission Recommendation 2011/24/EU – ICP elements',
        url: 'https://eur-lex.europa.eu/legal-content/EN/TXT/?uri=uriserv%3AOJ.L_.2011.011.01.0062.01.ENG&toc=OJ%3AL%3A2011%3A011%3ATOC'
      },
      {
        label: 'Q&A due diligence on restrictive measures for EU business dealings with Iran, 2019',
        url: 'https://ec.europa.eu/info/sites/info/files/business_economy_euro/banking_and_finance/documents/faqs-restrictive-measures-iran_en.pdf'
      },
      {
        label: 'Secondary Sanctions’ Implications and the Transatlantic Relationship, UK Finance and Atlantic Council, September 2019',
        url: 'https://www.atlanticcouncil.org/wp-content/uploads/2019/09/SecondarySanctions_Final.pdf'
      },
      {
        label: 'Risk management principles guide for sending humanitarian funds into Syria and similar high risk jurisdictions, Association of Certified Anti-Money Laundering Specialists (ACAMS), February 2020',
        url: 'http://files.acams.org/pdfs/2020/The-Risk-Management-Principles-Guide-for-Sending-Humanitarian-Funds-into-Syria-and-Similar-High-Risk-Jurisdictions.pdf'
      },
      {
        label: 'Methodology for identifying high-risk third countries under Directive (EU) 2015/849, European Commission, May 2020',
        url: 'https://ec.europa.eu/info/sites/info/files/business_economy_euro/banking_and_finance/documents/200507-anti-money-laundering-terrorism-financing-action-plan-methodology_en.pdf'
      },
      {
        label: 'Due Diligence Helpdesk on EU sanctions for EU SMEs dealing with Iran, October 2020',
        url: 'https://www.sanctions-helpdesk.eu/'
      },
      {
        label: 'Sanctions Tool Iran, October 2020',
        url: 'https://sanctions-tool.ec.europa.eu/'
      },
      {
        label: 'EU Commission Guidance Note on the provision of humanitarian aid to countries and areas subject to EU sanctions (Syria, Iran, Venezuela, and Nicaragua), (updated in November 2020)',
        url: 'https://ec.europa.eu/info/sites/info/files/business_economy_euro/banking_and_finance/documents/201116-humanitarian-aid-guidance-note_en.pdf'
      }
    ]
  },
  {
    name: 'The Wassenaar Arrangement',
    items: [
      {
        label: 'Compendium of best practice documents, The Wassenaar Arrangement on export controls for conventional arms and dual-use goods and technologies – December 2019',
        url: 'https://www.wassenaar.org/app/uploads/2019/12/WA-DOC-19-PUB-005-Public-Docs-Vol-III-Comp.-of-Best-Practice-Documents-Dec.-2019.pdf'
      },
      {
        label: 'Industry and Academia – Best practice guidelines on internal compliance programmes or dual-use goods and technologies, 2011',
        url: 'https://www.wassenaar.org/app/uploads/2019/consolidated/2-Internal-Compliance-Programmes.pdf'
      },
      {
        label: 'Introduction to End User/End Use Controls for Exports of Military-List Equipment, July 2014',
        url: 'https://www.wassenaar.org/app/uploads/2019/consolidated/End-User-Use-Controls-Export-ML-Equipment.pdf'
      }
    ]
  },
  {
    name: 'US',
    items: [
      {
        label: 'US EAR Export Administration Regulations',
        url: 'https://www.ecfr.gov/current/title-15/subtitle-B/chapter-VII/subchapter-C'
      },
      {
        label: 'US ITAR International Traffic in Arms Regulations',
        url: 'https://www.ecfr.gov/current/title-22/chapter-I/subchapter-M'
      },
      {
        label: 'Framework for OFAC Compliance Commitments',
        url: 'https://www.treasury.gov/resource-center/sanctions/Documents/framework_ofac_cc.pdf'
      },
      {
        label: 'Starting an OFAC Compliance Programme',
        url: 'https://www.treasury.gov/resource-center/faqs/Sanctions/Pages/faq_compliance.aspx#start'
      },
      {
        label: 'Compliance for Internet, Web Based Activities, and Personal Communications',
        url: 'https://www.treasury.gov/resource-center/faqs/Sanctions/Pages/faq_compliance.aspx#online'
      },
      {
        label: 'Compliance for the Insurance Industry',
        url: 'https://www.treasury.gov/resource-center/faqs/Sanctions/Pages/faq_compliance.aspx#insurance'
      },
      {
        label: 'OFAC Art Advisory, October 2020',
        url: 'https://home.treasury.gov/system/files/126/ofac_art_advisory_10302020.pdf'
      },
      {
        label: 'Additional Questions from Financial Institutions',
        url: 'https://www.treasury.gov/resource-center/faqs/Sanctions/Pages/faq_compliance.aspx#other_fi'
      },
      {
        label: 'Department of Commerce Bureau of Industry and Security, Compliance Guidelines: How to develop an export management and compliance programme, November 2013',
        url: 'https://www.bis.doc.gov/index.php/documents/compliance-training/export-management-compliance/1256-emcp-guidelines-november-2013/file'
      },
      {
        label: 'Department of Commerce Bureau of Industry and Security, Country Guidance',
        url: 'https://www.bis.doc.gov/index.php/policy-guidance/country-guidance'
      },
      {
        label: 'Banking Part 504 Regulations AML/sanctions compliance programmes, New York State Department of Financial Services (NY DFS), and ACAMS Guidance, 2017',
        url: 'https://www.dfs.ny.gov/docs/legal/regulations/adoptions/dfsp504t.pdf'
      },
      {
        label: 'Department of Commerce Bureau of Industry and Security, Export Compliance Guidelines, January 2017',
        url: 'https://www.bis.doc.gov/index.php/documents/pdfs/1641-ecp/file'
      },
      {
        label: 'Department of Justice, Evaluation of Corporate Compliance Programmes, 2019',
        url: 'https://www.justice.gov/criminal-fraud/page/file/937501/download'
      },
      {
        label: 'National Strategy for Combatting Terrorist and Other Illicit Financing, 2020',
        url: 'https://home.treasury.gov/system/files/136/National-Strategy-to-Counter-Illicit-Financev2.pdf'
      },
      {
        label: 'OFAC, State Department and US Coast Guardian Global Sanctions Maritime Advisory, May 2020',
        url: 'https://www.treasury.gov/resource-center/sanctions/Programs/Documents/05142020_global_advisory_v1.pdf'
      },
      {
        label: 'Department of Commerce Bureau of Industry and Security, Russia, Pakistan, and Hong Kong Due Diligence Guidance',
        url: 'https://www.bis.doc.gov/index.php/policy-guidance/country-guidance'
      },
      {
        label: 'OFAC Sudan Programme and Darfur Guidance, as of 11 August 2020',
        url: 'https://home.treasury.gov/system/files/126/sudan_guidance_20200811.pdf'
      },
      {
        label: 'State Department Guidance on human rights compliance for products and services with surveillance capabilities, September 2020',
        url: 'https://www.state.gov/wp-content/uploads/2020/09/DRL-Industry-Guidance-Project-FINAL-508.pdf'
      }
    ]
  }
];

export default documents;