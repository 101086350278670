import React from 'react';
import PropTypes from 'prop-types';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';

const CoboTableBody = (props) => {
  const {
    columns,
    filteredAndSortedData,
    page,
    rowsPerPage,
    handleRowClick
  } = props;

  return (
    <TableBody>
      {filteredAndSortedData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
        .map(row => (
          <TableRow
            hover
            key={row.id}
            onClick={() => handleRowClick(row)}
            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
            {columns.map(c => {
              const rawValue = row[c.id];
              const value = c.formattedValue ? c.formattedValue(rawValue) : rawValue;
              const style = c.style ? c.style(rawValue) : {};
              return (
                <TableCell
                  key={`body-cell${c.id}`}
                  sx={{ cursor: 'pointer', ...style }}>
                  {value}
                </TableCell>
              );
            })}
          </TableRow>
        ))}
    </TableBody>
  );
}

CoboTableBody.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    sortingEnabled: PropTypes.bool,
    style: PropTypes.func,
    filterInput: PropTypes.func,
    formattedValue: PropTypes.func,
    isFilterMatched: PropTypes.func
  })).isRequired,
  filteredAndSortedData: PropTypes.arrayOf(PropTypes.object),
  page: PropTypes.number,
  rowsPerPage: PropTypes.number,
  handleRowClick: PropTypes.func
};

export default CoboTableBody;