import PropTypes from 'prop-types';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import EmbargoList from './EmbargoList';

const embargoListTypes = [
  ['CH', 'Swiss'],
  ['EU', 'Europe'],
  ['US', 'US']
];

const CountryEmbargoDialog = (props) => {
  const {
    countryProperties,
    open,
    onClose
  } = props;

  const embargos = countryProperties.EMBARGO;

  if (!embargos) return null;

  return (
    <Dialog open={open} onClose={onClose} maxWidth='sm' fullWidth={true}>
      <DialogTitle align='center'>
        {countryProperties.NAME}
      </DialogTitle>
      {embargoListTypes.map(e => (
        <EmbargoList
          key={e[1]}
          embargo={embargos[e[0]]} 
          embargoListName={e[1]}/>
      ))}
    </Dialog>
  );
}

CountryEmbargoDialog.propTypes = {
  countryProperties: PropTypes.shape({
    NAME: PropTypes.string.isRequired,
    EMBARGO: PropTypes.object,
  }).isRequired,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired
};

export default CountryEmbargoDialog;