import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import useWindowDimensions from './useWindowDimensions';

export const BRYTER_FORM_MAPPER = {
  transactionCheck: {
    path: 'transactioncheck',
    label: 'Transaction Check',
    status: 'Pending',
    url: process.env.REACT_APP_TRANSACTION_CHECK_URL,
    navigateBackPath: '/transactionchecks'
  },
  transactionCheckResult: {
    path: 'transactioncheck',
    label: 'Transaction Check Result',
    status: 'Completed',
    url: process.env.REACT_APP_TRANSACTION_CHECK_RESULT_URL,
    navigateBackPath: '/transactionchecks'
  },
  quickCheck: {
    path: 'quickcheck',
    label: 'Quick Check (Coming soon)',
    status: 'Pending',
    url: process.env.REACT_APP_QUICK_CHECK_URL,
    navigateBackPath: '/transactionchecks'
  },
  quickCheckResult: {
    path: 'quickcheck',
    label: 'Quick Check Result',
    status: 'Completed',
    url: process.env.REACT_APP_TRANSACTION_CHECK_RESULT_URL,
    navigateBackPath: '/transactionchecks'
  },
  sanctionSearch: {
    path: 'sanctionsearch',
    label: 'Sanction Search',
    status: 'Pending',
    url: process.env.REACT_APP_SANCTION_SEARCH_URL,
    navigateBackPath: '/sanctionsearches'
  },
  sanctionSearchResult: {
    path: 'sanctionsearch',
    label: 'Sanction Search Result',
    status: 'Completed',
    url: process.env.REACT_APP_SANCTION_SEARCH_RESULT_URL,
    navigateBackPath: '/sanctionsearches'
  }
}

const constructBryterUrl = (baseUrl, id, userId, sessionId) => {
  return sessionId ?
    `${baseUrl}/sessions/${sessionId}?id=${id}&userId=${userId}` :
    `${baseUrl}?id=${id}&userId=${userId}`
};

const BryterForm = (props) => {
  const {
    bryterFormMap,
    processId,
    userId,
    sessionId,
    navigateBack
  } = props;

  const { height } = useWindowDimensions();

  return (
    <Box sx={{ flexDirection: 'column', height: height - 150}}>
      <iframe
        id={`bryter-form-${bryterFormMap.path}`}
        title={`bryter-form-${bryterFormMap.path}`}
        style={{ width: '100%', height: '100%', borderWidth: 0 }}
        src={constructBryterUrl(bryterFormMap.url, processId, userId, sessionId)}
      />
      <Button
        variant="contained"
        color="bryterButton"
        label='goBack'
        sx={{
          position: 'absolute',
          bottom: 20,
          right: 20
        }}
        onClick={() => navigateBack(bryterFormMap.navigateBackPath)}>
        {(bryterFormMap.status === 'Completed' || bryterFormMap.path === 'sanctionSearch')? 'Back': 'Save and go back'}
      </Button>
    </Box>
  );
}

BryterForm.propTypes = {
  bryterFormMap: PropTypes.shape({
    path: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    status: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
    navigateBackPath: PropTypes.string.isRequired
  }),
  processId: PropTypes.string.isRequired,
  userId: PropTypes.string.isRequired,
  sessionId: PropTypes.string,
  navigateBack: PropTypes.func.isRequired
};

BryterForm.defaultProps = {
  sessionId: null
};

export default BryterForm;