import PropTypes from 'prop-types';
import React, { memo, useState } from 'react';
import CountryEmbargoDialog from './CountryEmbargoDialog';
import orange from '@mui/material/colors/orange';
import grey from '@mui/material/colors/grey';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import {
  ZoomableGroup,
  ComposableMap,
  Geographies,
  Geography
} from 'react-simple-maps';
import useWindowDimensions from './useWindowDimensions';

const embargoTypeColorMap = {
  CH: {
    default: '#FF0000b5',
    hover: '#FF0000'
  },
  EU: {
    default: '#FFCC00b5',
    hover: '#FFCC00'
  },
  US: {
    default: '#002868b5',
    hover: '#002868'
  },
  ALL: {
    default: orange[600],
    hover: orange[900]
  },
  EMPTY: {
    default: grey[300],
    hover: grey[400]
  },
};

const MapChart = (props) => {
  const { 
    setTooltipContent, 
    worldMapJson, 
    scrollToCoboTable 
  } = props;

  const [selectedEmbargoListType, setSelectedEmbargoListType] = useState('CH');
  const [openCountryEmbargoDialog, setOpenCountryEmbargoDialog] = useState(false);
  const [selectedCountryProperties, setSelectedCountryProperties] = useState();

  const handleSelectChange = (event) => {
    setSelectedEmbargoListType(event.target.value);
  };

  const handleMapClick = (countryProperties) => {
    if (!countryProperties.EMBARGO) return;
    setSelectedCountryProperties(countryProperties);
    setOpenCountryEmbargoDialog(true);
  };

  const getCountryColorStyle = (embargo) => {
    const isPainted = embargo && (selectedEmbargoListType === 'ALL' || embargo[selectedEmbargoListType]);
    const embargoColor = isPainted ? embargoTypeColorMap[selectedEmbargoListType] : embargoTypeColorMap.EMPTY;
    const cursor = embargo ? 'pointer' : 'inherit';

    return {
      default: {
        fill: embargoColor.default,
        outline: 'none',
        cursor: cursor
      },
      hover: {
        fill: embargoColor.hover,
        outline: 'none',
        cursor: cursor
      },
      pressed: {
        fill: '#E42',
        outline: 'none',
        cursor: cursor
      }
    };
  };

  const { height, width } = useWindowDimensions();

  const calculateMapSize = (width) => {
    const mapWidth = width < 600 ? width - 50 : width - 300;
    return {
      width: mapWidth,
      height: mapWidth * (6 / 8),
      maxHeight: height - 200
    }
  };

  return (
    <>
      <Box sx={{ minWidth: 120, maxWidth: 300 }}>
        <Box sx={{ display: 'flex', flexDirection: 'row' }}>
          <FormControl fullWidth>
            <InputLabel id='measure-param-select-label'>Select a parameter</InputLabel>
            <Select
              labelId='embargo-select-label'
              id='embargo-select'
              value={selectedEmbargoListType}
              label='Select a parameter'
              onChange={handleSelectChange}>
              <MenuItem value='CH'>Swiss Only</MenuItem>
              <MenuItem value='EU'>EU Only</MenuItem>
              <MenuItem value='US'>US Only</MenuItem>
              <MenuItem value='ALL'>All combined</MenuItem>
            </Select>
          </FormControl>
          <Button variant='text' fullWidth sx={{'&:hover': { backgroundColor: '#fff'}}} onClick={scrollToCoboTable}>Go to Table</Button>
        </Box>
      </Box>
      <Box>
        <ComposableMap data-tip='' projectionConfig={{ scale: 200 }} style={{ ...calculateMapSize(width) }}>
          <ZoomableGroup zoom={1}>
            <Geographies geography={worldMapJson}>
              {({ geographies }) =>
                geographies.map(geo => (
                  <Geography
                    key={geo.rsmKey}
                    geography={geo}
                    onMouseEnter={() => setTooltipContent(geo.properties.NAME)}
                    onMouseLeave={() => setTooltipContent('')}
                    onClick={() => handleMapClick(geo.properties)}
                    style={{ ...getCountryColorStyle(geo.properties.EMBARGO) }}
                  />
                ))}
            </Geographies>
          </ZoomableGroup>
        </ComposableMap>
        {selectedCountryProperties &&
          <CountryEmbargoDialog
            countryProperties={selectedCountryProperties}
            open={openCountryEmbargoDialog}
            onClose={() => setOpenCountryEmbargoDialog(false)}/>}
      </Box>
    </>
  );
};

MapChart.propTypes = {
  setTooltipContent: PropTypes.func.isRequired,
  worldMapJson: PropTypes.object.isRequired
};

export default memo(MapChart);
