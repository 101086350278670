import React, { useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import Loading from './Loading';

const Login = () => {
  const { isAuthenticated, loginWithRedirect, isLoading } = useAuth0();

  useEffect(() => {
    const location = window.location;
    const href = location.pathname !== '/' ?
      window.location.href :
      `${window.location.origin}/transactionchecks`
    if (!isLoading && !isAuthenticated) {
      loginWithRedirect({ redirectUri: href });
    }
  }, [isAuthenticated, isLoading, loginWithRedirect]);

  return (
    <Loading open={isLoading} />
  );
};

export default Login;