import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import PageTitle from '../components/PageTitle';
import * as styles from '../styles/TransactionChecks.style';
import VideoPlayer from '../components/VideoPlayer';

const videos = [
  {
    name: 'Vorstellungsvideo',
    title: 'Vorstellungsvideo'
  },
  {
    name: 'Sanktionslistenprüfung',
    title: 'Sanktionslistenprüfung'
  },
  {
    name: 'Transaktionsprüfung',
    title: 'Transaktionsprüfung'
  },
  {
    name: 'IntroductionEN',
    title: 'Introduction EN'
  },
  {
    name: 'SanctionsSearchEN',
    title: 'Sanctions Search EN'
  }
];

const Instructions = ({ title }) => (
  <Box sx={styles.boxSx()}>
    <PageTitle title={title} />
    <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
      {videos.map(v => (
        <VideoPlayer
          key={v.name}
          title={v.title} 
          url={`videos/${v.name}.mp4`}/>
      ))}
    </Box>
  </Box>
);

Instructions.propTypes = {
  title: PropTypes.string.isRequired
};

export default Instructions;