import React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableSortLabel from '@mui/material/TableSortLabel';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import visuallyHidden from '@mui/utils/visuallyHidden';

const CoboTableHead = (props) => {
  const {
    columns,
    filters,
    order,
    orderBy,
    handleFilterInputChange,
    resetButtonDisabled,
    handleResetClick,
    handleRequestSort
  } = props;

  return (
    <TableHead>
      <TableRow>
        {columns.map(c => {
          return (
            <TableCell
              sx={{ paddingLeft: 1 }}
              key={`filter-cell-${c.id}`}>
              {c.filterInput && c.filterInput(filters[c.id], c.id, handleFilterInputChange)}
            </TableCell>
          );
        })}
        <TableCell
          sx={{ paddingLeft: 1, paddingRight: 0 }}>
          <Button
            variant='outlined'
            disabled={resetButtonDisabled}
            onClick={() => handleResetClick()}>
            Reset
          </Button>
        </TableCell>
      </TableRow>
      <TableRow>
        {columns.map(c => (
          <TableCell
            sx={{ [`&.${tableCellClasses.head}`]: { bgcolor: '#f8f8f8', color: '#7a7a7a', top: 70 } }}
            key={`head-cell-${c.id}`}
            align='left'>
            {c.sortingEnabled ? <TableSortLabel
              active={orderBy === c.id}
              direction={orderBy === c.id ? order : 'asc'}
              onClick={() => handleRequestSort(c.id)}
            >
              {c.label}
              {orderBy === c.id ? (
                <Box component='span' sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel> : c.label}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

CoboTableHead.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    sortingEnabled: PropTypes.bool,
    style: PropTypes.func,
    filterInput: PropTypes.func,
    formattedValue: PropTypes.func,
    isFilterMatched: PropTypes.func
  })).isRequired,
  filters: PropTypes.object.isRequired,
  order: PropTypes.string.isRequired,
  orderBy: PropTypes.string.isRequired,
  handleFilterInputChange: PropTypes.func.isRequired,
  resetButtonDisabled: PropTypes.bool.isRequired,
  handleResetClick: PropTypes.func.isRequired,
  handleRequestSort: PropTypes.func.isRequired
};

export default CoboTableHead;