import Chip from '@mui/material/Chip';
import { textFilterInput, selectFilterInput } from '../helper/coboTableFilterComponents';

const riskLevelColorMap = {
	low: "success",
	mid: "warning",
	high: "error"
}

const riskLevelMenuItems = [['low', 'low'], ['mid', 'mid'], ['high', 'high']];

const statusMenuItems = [['Pending', 'Pending'], ['Completed', 'Completed']];

const transactionTableColumns = [
	{
		id: 'caseNo',
		label: 'Case No',
		sortingEnabled: true,
		filterInput: textFilterInput
	},
	{
		id: 'customerRef',
		label: 'Customer Reference',
		sortingEnabled: true,
		filterInput: textFilterInput
	},
	{
		id: 'businessPartner',
		label: 'Business Partner',
		sortingEnabled: true,
		filterInput: textFilterInput
	},
	{
		id: 'country',
		label: 'Country',
		sortingEnabled: true,
		filterInput: textFilterInput
	},
	{
		id: 'status',
		label: 'Status',
		sortingEnabled: true,
		filterInput: selectFilterInput(statusMenuItems)
	},
	{
		id: 'riskLevel',
		label: 'Risk',
		formattedValue: (riskLevel) => {
			if (riskLevel) {
				return (
					<Chip
						label={riskLevel}
						color={riskLevelColorMap[riskLevel]} />
				);
			}
			return null;
		},
		filterInput: selectFilterInput(riskLevelMenuItems)
	}
];

export default transactionTableColumns;