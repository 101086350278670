import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';

const textFilterInput = (value, id, onChange) => (
  <TextField
    fullWidth
    id={`filter-input-${id}`}
    label='Filter'
    variant='standard'
    value={value}
    onChange={(event) => onChange(event, id)} />
);

const selectFilterInput = (menuItems) => ((value, id, onChange) => (
  <TextField
    select
    fullWidth
    id={`filter-input-${id}`}
    label='Filter'
    variant='standard'
    value={value}
    onChange={(event) => onChange(event, id)}>
    <MenuItem key='select' value=''>
      -- Select --
    </MenuItem>
    {menuItems.map(item => (
      <MenuItem key={item[0]} value={item[0]}>
        {item[1]}
      </MenuItem>
    ))}
  </TextField>
))

export { textFilterInput, selectFilterInput }