import React, { useContext, useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { store } from '../store';
import Typography from '@mui/material/Typography';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Box from '@mui/material/Box';

const Profile = () => {
  const { dispatch } = useContext(store)
  const { 
    user, 
    isAuthenticated, 
    getIdTokenClaims,
    logout
  } = useAuth0();

  const [anchorEl, setAnchorEl] = useState(null);
  // const authDomain = process.env.REACT_APP_AUTH0_DOMAIN;

  useEffect(() => {
    const setIdToken = async () => {
      try {
        const idClaims = await getIdTokenClaims();

        // const accessToken = await getAccessTokenSilently({
        //   audience: `https://${authDomain}/api/v2/`,
        //   scope: 'read:users',
        // });

        // console.log({accessToken: accessToken})

        dispatch({type: 'ID_TOKEN', payload: idClaims.__raw});
      } catch (e) {
        console.log({error: e});
      }
    };
    if(isAuthenticated) setIdToken();
  }, [dispatch, getIdTokenClaims, isAuthenticated]);

  
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    (isAuthenticated && user)? (
      <Box sx={{ display:'flex'}}>
        <Typography 
          noWrap component='div'
          sx={{ alignSelf: 'center'}}>
          {user.name}
        </Typography>
        <KeyboardArrowDownIcon 
          sx={{ alignSelf: 'center', ml: '10px'}} 
          onClick={handleClick}/>
          <Menu
            id='basic-menu'
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              'aria-labelledby': 'basic-button',
            }}
          >
            <MenuItem onClick={() => {
              logout({ returnTo: 'https://cobo.ai' });
              handleClose()
            }}>
              Logout
            </MenuItem>
          </Menu>
      </Box>
    ): null
  );
};

export default Profile;