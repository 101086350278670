import PropTypes from 'prop-types';
import Drawer from '@mui/material/Drawer';
import Box from '@mui/material/Box';
import SideDrawer from './SideDrawer';
import {
  drawerSx,
  coboIconSx,
  boxDrawerSx
} from '../styles/SideMenu.style';

const DrawerBox = (props) => {
  const {
    mobileOpen,
    selectedItemIndex,
    handleDrawerToggle,
    handleMenuItemClick
  } = props;

  return (
    <Box
      component='nav'
      sx={boxDrawerSx()}
      aria-label="side"
    >
      <Drawer
        variant="temporary"
        open={mobileOpen}
        onClose={handleDrawerToggle}
        ModalProps={{
          keepMounted: true,
        }}
        sx={drawerSx(false)}
      >
        <Box sx={coboIconSx()} />
        <SideDrawer
          selectedItemIndex={selectedItemIndex}
          onItemClick={(event, index) => { handleMenuItemClick(index) }}
        />
      </Drawer>
      <Drawer
        variant="permanent"
        sx={drawerSx(true)}
        open
      >
        <Box sx={coboIconSx()} />
        <SideDrawer
          selectedItemIndex={selectedItemIndex}
          onItemClick={(event, index) => { handleMenuItemClick(index) }}
        />
      </Drawer>
    </Box>
  );
}

DrawerBox.propTypes = {
  mobileOpen: PropTypes.bool.isRequired,
  selectedItemIndex: PropTypes.number.isRequired,
  handleDrawerToggle: PropTypes.func.isRequired,
  handleMenuItemClick: PropTypes.func.isRequired
};

export default DrawerBox;