import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import ReactPlayer from 'react-player';
import useWindowDimensions from './useWindowDimensions';

const VideoPlayer = (props) => {
  const { url, title } = props;

  const { width } = useWindowDimensions();
  const playerSize = width < 600 ? width - 85 : 500

  return (
    <Box sx={{ m: 2 }}>
      <Typography variant='h6' color='primary' sx={{ fontWeight: 'bold'}}>
        {title}
      </Typography>
      <ReactPlayer 
        width={playerSize}
        controls url={url}
        config={{ file: { 
          attributes: {
            controlsList: 'nodownload'
          }
        }}}/>
    </Box>
  );
}

VideoPlayer.propTypes = {
  url: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired
};

export default VideoPlayer;