import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Table from '@mui/material/Table';
import TablePagination from '@mui/material/TablePagination';
import TableContainer from '@mui/material/TableContainer';
import CoboTableHead from './CoboTableHead';
import CoboTableBody from './CoboTableBody';

const descendingComparator = (paramA, paramB, orderBy) => {
  const a = Object.assign({}, paramA);
  const b = Object.assign({}, paramB);;
  a[orderBy] = paramA[orderBy] ? paramA[orderBy] : 'zzzzzzzzz';
  b[orderBy] = paramB[orderBy] ? paramB[orderBy] : 'zzzzzzzzz';

  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

const getComparator = (order, orderBy) => {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

const stableSort = (array, comparator) => {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const initializeFilters = (columns) => {
  const filters = {};

  columns.map(c => filters[c.id] = '');

  return filters;
};

const CoboTable = (props) => {
  const {
    data,
    columns,
    onRowClick,
    defaultColumnOrderId,
    orderDirection,
    defaultRowsPerPage
  } = props;

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(defaultRowsPerPage);
  const [order, setOrder] = useState(orderDirection);
  const [orderBy, setOrderBy] = useState(defaultColumnOrderId? defaultColumnOrderId: columns[0].id);
  const [filters, setFilters] = useState(initializeFilters(columns));
  const [resetButtonDisabled, setResetButtonDisabled] = useState(true);

  const isFiltered = (row) => {
    for (let i = 0; i < columns.length; i++) {
      const c = columns[i];
      if (filters[c.id]) {
        if (filters[c.id] !== '' && !row[c.id]) {
          return false;
        }
        if (c.isFilterMatched) {
          if(!c.isFilterMatched(row[c.id], filters[c.id])) return false;
        } else if (!row[c.id].toLowerCase().includes(filters[c.id].toLowerCase())) {
          return false;
        };
      }
    }

    return true;
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleRowClick = (row) => {
    if(onRowClick) onRowClick(row);
  };

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleFilterInputChange = (event, id) => {
    setFilters(prevFilters => {
      return {
        ...prevFilters,
        [id]: event.target.value
      }
    });
    setResetButtonDisabled(false);
    setPage(0);
  };

  const handleResetClick = () => {
    setFilters(initializeFilters(columns));
    setResetButtonDisabled(true);
    setPage(0);
  };

  const filteredAndSortedData = stableSort(data, getComparator(order, orderBy))
    .filter(row => isFiltered(row));

  return (
    <>
      <TableContainer sx={{ maxHeight: 700 }}>
        <Table stickyHeader sx={{ minWidth: 650 }} area-label='cobo-table'>
          <CoboTableHead 
            columns={columns}
            filters={filters}
            order={order}
            orderBy={orderBy}
            handleFilterInputChange={handleFilterInputChange}
            resetButtonDisabled={resetButtonDisabled}
            handleResetClick={handleResetClick}
            handleRequestSort={handleRequestSort}/>
          <CoboTableBody 
            columns={columns}
            filteredAndSortedData={filteredAndSortedData}
            page={page}
            rowsPerPage={rowsPerPage}
            handleRowClick={handleRowClick}/>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25, 100]}
        component='div'
        count={filteredAndSortedData.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </>
  );
}

CoboTable.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  columns: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    sortingEnabled: PropTypes.bool,
    style: PropTypes.func,
    filterInput: PropTypes.func,
    formattedValue: PropTypes.func,
    isFilterMatched: PropTypes.func
  })).isRequired,
  onRowClick: PropTypes.func,
  defaultColumnOrderId: PropTypes.string,
  orderDirection: PropTypes.string,
  defaultRowsPerPage: PropTypes.number
};

CoboTable.defaultProps = {
  onRowClick: null,
  defaultColumnOrderId: null,
  orderDirection: 'desc',
  defaultRowsPerPage: 10
};

export default CoboTable;