import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router';
import { useGetSanctionSearches, useCreateSanctionSearch } from '../utils/sanctionSearchService';
import AddIcon from '@mui/icons-material/Add';
import Box from '@mui/material/Box';
import Fab from '@mui/material/Fab';
import CircularProgress from '@mui/material/CircularProgress';
import CoboTable from '../components/CoboTable';
import PageTitle from '../components/PageTitle';
import sanctionSearchTableColumns from '../components/sanctionSearchTableColumns';
import { addIconSx, boxSx, fabSx } from '../styles/TransactionChecks.style';

const getMatchOrConstruct = (sanctionSearches, i) => {
  const matchId = `match${(i + 1)}`;
  if (sanctionSearches
    && sanctionSearches.matches
    && sanctionSearches.matches[i]) {
      const match = sanctionSearches.matches[i];
    return {
      [matchId]: { name: match.name, isConfirmed: match.isConfirmed.trim() === 'Ja' }
    };
  }
  return {
    [matchId]: { name: '-', isConfirmed: false }
  }
};

const sanctionSearchDataMapping = (sanctionSearches) => {
  if (!sanctionSearches) return

  const copySanctionSearches = Object.assign([], sanctionSearches);

  return copySanctionSearches
    .filter(s => s.status === 'Completed')
    .map(s => ({
      ...s,
      query: {
        name: s.searchName,
        hasAnyMatch: s.matches.some(m => m.isConfirmed.trim() === 'Ja')
      },
      ...(Array.from({ length: 5 }, (_, i) => i)
        .reduce((prev, cur, index) => ({ ...prev, ...getMatchOrConstruct(s, index) }), {}))
    }));
};

const SanctionSearches = ({ title }) => {
  const [getSanctionSearchesResponse, getSanctionSearches] = useGetSanctionSearches();
	const [createSanctionSearchResponse, createSanctionSearch] = useCreateSanctionSearch();
  const [sanctionSearches, setSanctionSearches] = useState();
  const navigate = useNavigate();

  useEffect(() => {
    getSanctionSearches(true)
		if(!getSanctionSearchesResponse.isLoading) {
			setSanctionSearches(getSanctionSearchesResponse.data.Items);
		}
  }, [getSanctionSearches, getSanctionSearchesResponse]);

  useEffect(() => {
    if(!createSanctionSearchResponse.isLoading) {
      navigate(`/sanctionsearch/${createSanctionSearchResponse.data.id}`);
    }
	}, [createSanctionSearchResponse, navigate]);

  const handleNewSanctionSearch = async () => {
    createSanctionSearch(true);
  };

  const handleTableRowClick = (row) => {
    navigate(`/sanctionsearch/${row.id}/`);
  };

  return (
    <Box sx={boxSx()}>
      <PageTitle title={title} />
      <Fab
        variant="extended"
        color="primary"
        label='New Sanction Search'
        sx={fabSx()}
        onClick={() => handleNewSanctionSearch()}>
        <AddIcon sx={addIconSx()} />New
      </Fab>
      {sanctionSearches ?
        <CoboTable
          data={sanctionSearchDataMapping(sanctionSearches)}
          columns={sanctionSearchTableColumns}
          onRowClick={(row) => handleTableRowClick(row)} /> :
        <Box sx={{ display: 'flex' }}>
          <CircularProgress />
        </Box>}
    </Box>
  );
};

SanctionSearches.propTypes = {
  title: PropTypes.string.isRequired
};

export default SanctionSearches;