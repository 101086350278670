const drawerWidth = 240;

const menuIconButtonSx = () => ({ mr: 2, display: { sm: 'none' } })

const drawerSx = (isPermanent) => ({
  display: isPermanent? { xs: 'none', sm: 'block' }: { xs: 'block', sm: 'none' },
  '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
})

const appBarSx = () => ({
  backgroundColor: 'white',
  color: 'black',
  width: { sm: `calc(100% - ${drawerWidth}px)`},
  ml: { sm : `${drawerWidth}px`}
})

const titleSx = () => ({
  display: { xs: 'none', sm: 'block'}
})

const coboIconSx = () => ({
  mt: 3,
  ml: 3,
  width: 150,
  height: 100,
  backgroundImage: 'url(https://cobo.ai/wp-content/uploads/2020/12/White-logo-no-background-1.png)',
  backgroundSize: 'contain',
  backgroundPosition: '1 1/2',
  backgroundRepeat: 'no-repeat'
})

const listSx = () => ({ mt: 3 });

const listItemIconSx = () => ({
  minWidth: 30,
  '& .MuiSvgIcon-root': { color: 'white'}
});

const listItemButtonSx = (selected) => ({
  width: '90%',
  borderRadius: 2,
  ml: 'auto',
  mr: 'auto',
  mt: 1,
  backgroundColor: selected? 'secondary.main': 'primary.main',
  color: selected? 'white': 'tertiary.main',
  '&:hover': {
    backgroundColor: selected? 'secondary.main': 'primary.main',
    color: selected? 'white': 'tertiary.main',
  }
});

const boxContainerSx = () => ({ 
  display: 'flex',
});

const boxProfileSx = () => ({ ml: 'auto', mr: 0 });

const boxDrawerSx = () => ({ width: { sm: drawerWidth }, flexShrink: { sm: 0 } });

const boxPageSx = () => ({ 
  flexGrow: 1, 
  p: 3, 
  width: { sm: `calc(100% - ${drawerWidth}px)`, xs: `calc(100% - ${drawerWidth}px)` }
});

export { 
  menuIconButtonSx,
  drawerSx,
  appBarSx,
  titleSx,
  coboIconSx,
  listSx,
  listItemIconSx, 
  listItemButtonSx,
  boxContainerSx,
  boxProfileSx,
  boxDrawerSx,
  boxPageSx
}
