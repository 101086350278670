import red from '@mui/material/colors/red';
import green from '@mui/material/colors/green';
import { textFilterInput } from '../helper/coboTableFilterComponents';

const matchColumn = (number) => ({
  id : `match${number}`,
  label: `Match ${number}`,
  sortingEnabled: false,
  formattedValue: (value) => (value.name),
  style: (value) => ({color: `${value.isConfirmed ? red[400] : 'black'}`})
});

const matchColumns = Array.from({length: 5}, (_, i) => i + 1).map(i => matchColumn(i));

const sanctionSearchTableColumns = [
	{
		id: 'caseNo',
		label: 'Case No',
		sortingEnabled: true,
		filterInput: textFilterInput
	},
	{
		id: 'query',
		label: 'Name',
		sortingEnabled: true,
		filterInput: textFilterInput,
		formattedValue: (value) => (value.name),
		style: (value) => ({color: `${!value.hasAnyMatch ? green[400] : 'black'}`})
	},
  ...matchColumns
];

export default sanctionSearchTableColumns;