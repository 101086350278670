import PropTypes from 'prop-types';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import FlashOnIcon from '@mui/icons-material/FlashOn';
import LibraryAddCheckIcon from '@mui/icons-material/LibraryAddCheck';
import { BRYTER_FORM_MAPPER } from './BryterForm';

const processess = [
  BRYTER_FORM_MAPPER['transactionCheck'], 
  BRYTER_FORM_MAPPER['quickCheck']
];

const SelectTransactionDialog = (props) => {
  const {
    open,
    onSelect,
    onClose
  } = props;

  const handleListItemClick = (value) => {
    onSelect(value);
  };
 
  return (
    <Dialog onClose={onClose} open={open}>
      <DialogTitle>
        Please select a process
      </DialogTitle>
      <List sx={{ pt:0 }}>
        {processess.map((p) => (
          <ListItem disabled={p.path === 'quickcheck'} button onClick={() => handleListItemClick(p)} key={p.path}>
            <ListItemIcon>
              {p.path === 'transactioncheck'? <LibraryAddCheckIcon />: <FlashOnIcon />}
            </ListItemIcon>
            <ListItemText primary={p.label} />
          </ListItem>
        ))}
      </List>
    </Dialog>
  );
}

SelectTransactionDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onSelect: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired
};
 
export default SelectTransactionDialog;