import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
import * as styles from '../styles/TransactionChecks.style';

const PageTitle = ({ title }) => {
  return (
    <Typography variant='h5' color='secondary' sx={styles.titleSx()}>
      {title}
    </Typography>
  );
}

PageTitle.propTypes = {
  title: PropTypes.string.isRequired
};

export default PageTitle;